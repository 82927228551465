(function () {
  'use strict';

  angular
    .module('events.deposits')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('events.deposits', {
        abstract: true,
        url: '/:eventId/deposits',
        templateUrl: 'events/deposits/deposits.tpl.html',
        resolve: {
          event: function ($stateParams, Restangular) {
            return Restangular.one('events', $stateParams.eventId).get();
          }
        }
      })
    ;
  }
}());
